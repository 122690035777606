(function(){
	$('.form-cadastro .nav .radio-custom').each(function(){
		$(this).click(function(){
			var id = $(this).find('input').attr('id');
			if(id == 'pessoaFisica'){
				$('.form-cadastro').addClass('pessoaFisica');
				$('.form-cadastro').removeClass('pessoaJuridica');
			}else{
				$('.form-cadastro').removeClass('pessoaFisica');
				$('.form-cadastro').addClass('pessoaJuridica');
			}
		});
	});
})();